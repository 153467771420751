<template>
    <div class="planWrap">

    </div>
</template>
<script>
import indexApi from '@/api/index';
export default {
    data(){
        return{

        }
    },
    created(){

    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>

</style>